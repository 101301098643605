import './App.scss';


import {Switch,Route}  from 'react-router-dom'
import Home from "./components/Home"
import CategoryDetail from './components/CategoryDetail/CategoryDetail';
import ProductInfo from './components/ProductInfo/ProductInfo';
import SignIn from './components/Authentication/SignIn'
import SignUp from './components/Authentication/SignUp'
import Cart from './components/Cart/Cart'
import MyProfile from './components/Profile/MyProfile'
import MyOrders from './components/Profile/MyOrders/MyOrders'
import MyWishlist from './components/Profile/MyWishlist/MyWishlist'
import MyPayments from './components/Profile/MyPayments/MyPayments';
import MerchantSignup from './components/Authentication/MerchantSignup'
import VerifyOtp from './components/Authentication/VerifyOtp';
import {connect} from 'react-redux'
import SimpleBackdrop from './components/Utils/SimpleBackdrop';
import MerchantProfile from './components/Merchant/MerchantProfile'
import MerchantInventory from './components/Merchant/MerchantInventory/MerchantInventory';
import MerchantOrders from './components/Merchant/MerchantOrders/MerchantOrders'
import MerchantPayments from './components/Merchant/MerchantPayments/MerchantPayments';
import Checkout from './components/Checkout/Checkout';
import SingleOrder from './components/Profile/MyOrders/SingleOrder';
import TransactionStatus from './components/TransactionStatus/TransactionStatus';
import TransactionStatusOffline from './components/TransactionStatus/TransactionStatusOffline'
import OfflinePay from './components/OfflinePay/OfflinePay'
import React from 'react';
import CreditLimitStatus from './components/TransactionStatus/CreditLimitStatus';
import GenerateMerchantQR from './components/Merchant/GenerateMerchantQR/GenerateMerchantQR';
import MerchantOrderDetail from './components/Merchant/MerchantOrders/MerchantOrderDetail';
import Refund from './components/Authentication/Refund';
import CasheGold from './components/TransactionStatus/CasheGold';
import Feedback from './components/TransactionStatus/Feedback';
import VerifyOrderOTP from './components/OfflinePay/VerifyOrderOTP';
import CompletedOrders from './components/Merchant/MerchantOrders/CompletedOrders';
import Settlements from './components/Merchant/Settlements/Settlements';
import Eligibility from './components/Merchant/Eligibility/Eligibility';
import OffersTo from './components/Merchant/GenerateMerchantQR/OffersTo';
import Sample from './components/Sample';
import CashbackAni from './components/TransactionStatus/CashbackAni';
import CameraComponent from './components/TransactionStatus/CameraComponent';

//pages
import ReturnPolicy from './pages/ReturnPolicy';
import ContactUs from './pages/ContactUs/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandConditions from './pages/TermsandConditions';
import AboutUs from './pages/AboutUs';
import CleverPeEco from './pages/CleverPeEco';
import HowtoBuy from './pages/HowtoBuy'
import Disclaimer from './pages/Disclaimer';
import Invoice from './components/Profile/MyOrders/Invoice';
import ComingSoon from './pages/ComingSoon';
import GenerateQRnew from './components/Merchant/GenerateMerchantQR/GenerateQRnew';
import {deleteUser} from './components/redux/user/userActions'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import MerchantSignupAF from './components/Authentication/MerchantSignupAF';
import MerchantSignupAB from './components/Authentication/MerchantSignupAB';
import Test from './components/Test/Test';
import MS1 from './components/Authentication/MS1';
import MS2 from './components/Authentication/MS2';
import MS3 from './components/Authentication/MS3';
import MS4 from './components/Authentication/MS4';
import UserHome from './components/UserDashboard/UserHome';
import UserSignIn from './components/Authentication/UserSignIn';
import UserVerifyOtp from './components/Authentication/UserVerifyOtp';
import UploadPicture from './components/TransactionStatus/UploadPicture';

function App(props) {
  const history = useHistory();

  if(props.user.user){
    axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/auth/checkToken`,{headers:{token:props.user.user}})
    .then(res=>{
      console.log(res)
      if(res.data.result==="unAuthorise"){
        props.deleteUser()
        history.replace('/signin');
        window.location.href="/signin"
      }
    })
  }
  //comment added
  return (
    <>
    <SimpleBackdrop open={props.loading} />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/cart" component={Home} />
      <Route path="/userhome" component={UserHome} />
      <Route path="/merchantsignupaf/:name" component={MerchantSignupAF} />
{/* 
      <Route path="/categories" component={CategoryDetail} />
      <Route path="/productinfo" component={ProductInfo} /> */}
      <Route path="/signin" component={SignIn} />
      <Route path="/usersignin" component={UserSignIn} />
      {/* <Route path="/verifyuserotp" component={UserVerifyOtp} /> */}
      <Route path="/signup" component={SignUp} />
      {/* <Route path="/ms1" component={MS1} />
      <Route path="/ms2" component={MS2} />
      <Route path="/ms3" component={MS3} />
      <Route path="/ms4" component={MS4} /> */}
      {/* <Route path="/cart" component={Cart} /> */}
      <Route path="/myprofile" component={MyProfile} />
      {/* <Route path="/myorders" component={MyOrders} /> */}
      {/* <Route path="/singleorder" component={SingleOrder} /> */}
      {/* <Route path="/mywishlist" component={MyWishlist} /> */}
      {/* <Route path="/mypayments" component={MyPayments} /> */}
      <Route path="/merchantsignup" component={MerchantSignup} />
      <Route path="/merchantinventory" component={MerchantInventory} />
      <Route path="/merchantorders" component={MerchantOrders} />
      <Route path="/completedorders" component={CompletedOrders} />
      <Route path="/settlements" component={Settlements} />
      <Route path="/merchantpayments" component={MerchantPayments} />
      {/* <Route path="/verifyotp" component={VerifyOtp} /> */}
      {/* <Route path="/verifyorderotp/:id" component={VerifyOrderOTP} /> */}
      <Route path="/merchantprofile" component={MerchantProfile} />
      {/* <Route path="/checkout" component={Checkout} /> */}
      <Route path="/transactionstatus" component={TransactionStatus} />
      <Route path="/transactionstatusoffline" component={TransactionStatusOffline} />
      <Route path="/cameracomponent" component={CameraComponent} />
      <Route path="/uploadpicture" component={UploadPicture} />
      <Route path="/creditlimitstatus" component={CreditLimitStatus}  />
      <Route path="/offlinepay/:id/:rg" component={OfflinePay} />
      {/* <Route path="/generatemerchantqr" component={GenerateMerchantQR} /> */}
      <Route path="/merchantorderdetails" component={MerchantOrderDetail} />
      <Route path="/returnpolicy" component={ReturnPolicy} />
      <Route path="/privacypolicy" component={PrivacyPolicy} />
      <Route path="/termsandconditions" component={TermsandConditions} />
      <Route path="/contactus" component={ContactUs} />
      <Route path="/aboutus" component={AboutUs} />
      <Route path="/disclaimer" component={Disclaimer} />
      {/* <Route path="/howtobuy" component={HowtoBuy} /> */}
      <Route path="/refund" component={Refund} />
      <Route path="/invoice" component={Invoice} />
      <Route path="/cashegold" component={CasheGold} />
      <Route path="/feedback" component={Feedback} />
      <Route path="/offersto" component={OffersTo} />
      <Route path="/GenerateQRnew" component={GenerateQRnew} />
      <Route path="/eligibility" component={Eligibility} />
      <Route path="/sample/:name" component={Sample} />
      <Route path="/cashback" component={CashbackAni} />
      <Route path="/cleverpeeco" component={CleverPeEco} />
    </Switch>
    </>
  );
}

const mapStateToProps = ({loading,cleverpeUser})=>{
  return {
    loading,
    user:cleverpeUser
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    deleteUser:()=>dispatch(deleteUser())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
// function App() {
//   const [loading,setLoading]=React.useState(true)

//   return (
//     <Switch>
//       <Route exact path="/" component={Home} />
//       <Route path="/categorydetail" component={CategoryDetail} />
//       <Route path="/productinfo" component={ProductInfo} />
//       <Route path="/signin" component={SignIn} />
//       <Route path="/signup" component={SignUp} />
//       <Route path="/cart" component={Cart} />
//       <Route path="/myprofile" component={MyProfile} />
//       <Route path="/myorders" component={MyOrders} />
//       <Route path="/mywishlist" component={MyWishlist} />
//       <Route path="/mypayments" component={MyPayments} />
//       <Route path="/merchantsignup" component={MerchantSignup} />
//       <Route path="/verifyotp" component={VerifyOtp} />
      
//     </Switch>
//   );
// }

// export default App;
